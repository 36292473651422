import {
  withRequestRetry,
  defaultFetchOptions,
  _handleJsonResponse,
  RequestErrorHandler,
} from './DataExtraction';
import { SAVE_RESULT, tokenizeRoute } from 'query/routes';

export const saveResult = async (
  resultValue: string,
  reviewStudyId: string,
  outcome_result_id: string,
  arm_id: string,
  timepoint_id: string,
  onError?: RequestErrorHandler
) => {
  return withRequestRetry(
    () =>
      _saveResult(
        resultValue,
        reviewStudyId,
        outcome_result_id,
        arm_id,
        timepoint_id
      ),
    onError
  );
};

const _saveResult = async (
  value: string,
  reviewStudyId: string,
  outcome_result_id: string,
  arm_id: string,
  timepoint_id: string
) => {
  const url = tokenizeRoute(SAVE_RESULT, {
    review_study_id: reviewStudyId,
  });
  const requestBody = {
    value,
    outcome_result_id,
    arm_id,
    timepoint_id,
  };

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify(requestBody),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};
