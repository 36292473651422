import {
  NonRetriableError,
  OfflineError,
  withRequestRetry,
  DEFAULT_REQUEST_TIMEOUT,
} from './DataExtraction';
import { CREATE_IDENTIFICATION_ROUTE, tokenizeRoute } from 'query/routes';
import { getStudyId } from 'query/study/Study';
import { Identification } from 'types';

export const createIdentification = async (
  values: Identification,
  onError?: (error: Error) => void
) => {
  // ignore id but grab rest of values
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updatedValues } = values;

  return withRequestRetry(
    () => _createIdentificationRequest(updatedValues),
    onError
  );
};

const _createIdentificationRequest = async (
  formData: Omit<Identification, 'id'>
) => {
  if (!navigator.onLine) {
    throw new OfflineError('No network connection detected');
  }

  const url = tokenizeRoute(CREATE_IDENTIFICATION_ROUTE, {
    review_study_id: getStudyId(),
  });

  const response = await fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: { 'Content-Type': 'application/json' },
    signal: (AbortSignal as any).timeout(DEFAULT_REQUEST_TIMEOUT),
  });

  if (response.status === 422) {
    const { errors } = JSON.parse(await response.text());
    throw new NonRetriableError(String(response.status), errors[0]);
  }

  if (response.status !== 201) {
    throw new NonRetriableError(String(response.status));
  }

  return await response.json();
};
