import pRetry, { FailedAttemptError } from 'p-retry';
import {
  DATA_EXTRACTION_ROUTE,
  tokenizeRoute,
  CREATE_STUDY_NOTE_ROUTE,
  UPDATE_STUDY_NOTE_ROUTE,
} from '../../routes';
import {
  sortTimepoints,
  sortOutcomes as sortOutcomesFn,
} from 'pages/DataExtraction/DataExtraction/dataUtils';
import {
  Arm,
  DataExtraction,
  Note,
  Outcome,
  ResultValue,
} from 'types/DataExtraction';

export type RequestErrorHandler = (error: Error) => Error;

export class NonRetriableError extends Error {
  statusCode: string;

  constructor(statusCode: string, message?: string) {
    super(message);
    this.name = 'NonRetriableError';
    this.statusCode = statusCode;
  }
}

export class OfflineError extends Error {
  statusCode: string;

  constructor(statusCode: string, message?: string) {
    super(message);
    this.name = 'OfflineError';
    this.statusCode = statusCode;
  }
}

export type DataExtractionError = NonRetriableError | OfflineError | Error;

export const DEFAULT_REQUEST_TIMEOUT = 60_000;

export const defaultFetchOptions = () => ({
  headers: { 'Content-Type': 'application/json' },
  signal: (AbortSignal as any).timeout(DEFAULT_REQUEST_TIMEOUT),
});
export const _handleJsonResponse = (response: Response) => {
  if (!response.ok) throw new NonRetriableError(String(response.status));

  return response.json();
};

export const withRequestRetry = (
  func: () => any,
  onError?: (error: Error) => void
) => {
  return pRetry(func, {
    // Default is exponential backoff, with 6 retries starting from 1 second => 32 seconds max wait time on the final attempt
    retries: 6,
    minTimeout: 1000, // Milliseconds before starting the first retry
    onFailedAttempt: (error: FailedAttemptError) => {
      if (error instanceof NonRetriableError) throw error;
      if (onError) onError(error);
    },
  });
};

export const getDataExtraction = async (
  reviewStudyId: string,
  onError?: RequestErrorHandler
): Promise<DataExtraction> => {
  return withRequestRetry(
    () => _getDataExtractionRequest(reviewStudyId),
    onError
  );
};

const _getDataExtractionRequest = async (
  reviewStudyId: string
): Promise<DataExtraction> => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(DATA_EXTRACTION_ROUTE, {
    review_study_id: reviewStudyId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    ...defaultFetchOptions(),
  })
    .then((response) => {
      return _handleJsonResponse(response);
    })
    .then((response) => sortOutcomes(response))
    .then((response) => sortOutcomeTimepoints(response))
    .then((response) => populateExtractionResults(response))
    .then((response) => populateOutcomeResultValues(response))
    .then((response) => {
      // populate arm position with index if it's not set
      return {
        ...response,
        arms: response.arms.map((arm: Arm, index: number) => {
          return { ...arm, position: arm.position ?? index };
        }),
      };
    });
};

export interface CheckExistingExtractionDataResponse {
  extracted: boolean;
}

const sortOutcomes = (dataExtraction: DataExtraction) => {
  dataExtraction.outcomes = dataExtraction.outcomes.sort(sortOutcomesFn());
  return dataExtraction;
};

const sortOutcomeTimepoints = (dataExtraction: DataExtraction) => {
  dataExtraction.outcomes.forEach((outcome) => {
    outcome.timepoints = outcome.timepoints.sort(sortTimepoints(outcome));
  });
  return dataExtraction;
};

const resultValueForCell = (
  outcome: Outcome,
  outcome_result_id: string,
  arm_id: string,
  timepoint_id: string
) => {
  // Cast to any as at this point the results are the raw array received from the server, pre-transformation
  const resultValues = outcome.result_values as any;
  return resultValues.find(
    (resultValue: ResultValue) =>
      resultValue.outcome_result_id === outcome_result_id &&
      resultValue.arm_id === arm_id &&
      resultValue.timepoint_id === timepoint_id
  );
};

// Create a result object for each cell so the results are easily accessible via arm/timepoint/outcomeResult
export const populateOutcomeResultValues = (dataExtraction: DataExtraction) => {
  return {
    ...dataExtraction,
    outcomes: dataExtraction.outcomes.map((outcome) => {
      const resultValuesByCell: Outcome['result_values'] = {};

      dataExtraction.arms.forEach((arm) => {
        outcome.timepoints.forEach((timepoint) => {
          outcome.outcome_results.forEach((or) => {
            const resultValue = resultValueForCell(
              outcome,
              or.id,
              arm.id,
              timepoint.id
            ) ?? { value: '' };
            resultValuesByCell[arm.id] ??= {};
            resultValuesByCell[arm.id][timepoint.id] ??= {};
            resultValuesByCell[arm.id][timepoint.id][or.id] = resultValue;
          });
        });
      });

      outcome.result_values = resultValuesByCell;
      return outcome;
    }),
  };
};

// Creates a result object for each arm/characteristic combo
export const populateExtractionResults = (dataExtraction: DataExtraction) => {
  return {
    ...dataExtraction,
    intervention_characteristics: dataExtraction.intervention_characteristics.map(
      (characteristic) => {
        const existingArms = characteristic.results.reduce((prev, result) => {
          return { ...prev, [result.arm_id]: result };
        }, {}) as Record<string, { arm_id: string; value: string }>;

        return {
          ...characteristic,
          results: dataExtraction.arms.map((arm) => {
            if (existingArms[arm.id]) return existingArms[arm.id];
            return {
              arm_id: arm.id,
              value: '',
            };
          }),
        };
      }
    ),
  };
};

export const createStudyNote = (
  note: string,
  category: string,
  reviewStudyId: string,
  onRequestError?: RequestErrorHandler
): Promise<Note> => {
  return withRequestRetry(
    () => _createStudyNote(note, category, reviewStudyId),
    onRequestError
  );
};

const _createStudyNote = async (
  note: string,
  category: string,
  reviewStudyId: string
): Promise<Note> => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(CREATE_STUDY_NOTE_ROUTE, {
    review_study_id: reviewStudyId,
  });

  const body: any = {
    note: note,
    category: category,
  };

  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const updateStudyNote = (
  note: string,
  noteId: number,
  reviewStudyId: string,
  onRequestError?: RequestErrorHandler
): Promise<Note> => {
  return withRequestRetry(
    () => _updateStudyNote(note, noteId, reviewStudyId),
    onRequestError
  );
};

const _updateStudyNote = async (
  note: string,
  noteId: number,
  reviewStudyId: string
): Promise<Note> => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(UPDATE_STUDY_NOTE_ROUTE, {
    review_study_id: reviewStudyId,
    note_id: noteId,
  });

  const body: any = { note: note };

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify(body),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};
